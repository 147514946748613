<template>
  <div>
    <create-project-by-template
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :template="chosenTemplate"
      @refetch-data="refetchData"
    />
    <b-card-code title="List of Templates">
      <div class="templates-view">
        <div class="template-item" v-for="template in getTemplates" :key="template.id">
          <b-row style="height: 100%">
            <b-col cols="4" class="template-description">
                <h3 class="mb-3 mt-3">{{template.related_project_name}}</h3>
                <p>{{template.comment}}</p>
                <span class="type-market"><b>({{template.type_market}})</b></span>
                <b-button
                  class="create-button"
                  variant="primary"
                  @click="createProjectByTemplate(template)"
                >Create Project</b-button>
            </b-col>
            <b-col cols="7">
              <b-card-text style="height: 100%">
                <iframe
                  allowfullscreen="true"
                  :src="template.development_url + '?isHosting=true'"
                  title=""
                  style="height: 100%; width: 80%"
                ></iframe>
              </b-card-text>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card-code>
  </div>
</template>

<script>
import store from "@/store";
import CreateProjectByTemplate from "./CreateProjectByTemplate.vue"
import BCardCode from "@core/components/b-card-code";
import {
  BCardText,
  BCol,
  BFormGroup,
} from "bootstrap-vue";

export default {
  components: {
    BCol,
    BCardText,
    BFormGroup,
    BCardCode,
    CreateProjectByTemplate,
  },
  data() {
    return {
      chosenTemplate: {rlated_project_name: null},
      isAddNewUserSidebarActive: false,
    }
  },
  computed: {
    getTemplates() {
      return this.$store.getters["Templates/getTemplates"]
    },
  },
  methods: {
    createProjectByTemplate(template) {
      this.isAddNewUserSidebarActive = true
      this.chosenTemplate = template
    },
  },
  setup() {
    store.dispatch("Templates/GET_ALL_TEMPLATES")
  }
}
</script>

<style lang="scss">
  .templates-view {
    display: flex;
    flex-direction: column;
    gap: 50px;

    .template-item {
      height: 50vh;

      .template-description {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .type-market {
          align-self: flex-end;
        }

        .create-button {
          margin-top: auto;
        }
      }
    }
  }
  
  
</style>