<template>
    <div>
      <b-sidebar
        id="add-new-user-sidebar"
        :visible="isAddNewUserSidebarActive"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        right
        @hidden="resetForm"
        @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
      >
        <template #default="{ hide }">
          <!-- Header -->
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              content-sidebar-header
              px-2
              py-1
            "
          >
            <h5 class="mb-0">Add New Project</h5>
  
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
  
          <!-- BODY -->
          <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
            <!-- Form -->
            <b-form
              class="p-2"
              @submit.prevent="handleSubmit(callOnSubmit)"
              @reset.prevent="resetForm"
            >
              <!-- Project Licenses -->
              <b-form-group
                label="Chose Project license:"
              >
                <div style="display: flex">
                  <b-form-radio-group
                    v-model="productData.project_license_type"
                    :options="optionsProjectLicenseType"
                    class="mb-1"
                    value-field="item"
                    text-field="name"
                    disabled-field="notEnabled"
                  ></b-form-radio-group>
                  <a href="/pricing" target="_blank" title="Click to see differents">
                    <feather-icon
                      class="ml-1 cursor-pointer"
                      icon="InfoIcon"
                      size="24"
                    />
                  </a>
                </div>
              </b-form-group>
              <div class="mb-2">
                <span class="mr-1">Left {{ getChosenLicenseType }} licenses:</span>
                <span :class="{'not-enough-license': getCountChosenLicenseLeft < 1}">
                  {{ getCountChosenLicenseLeft }}
                </span>
              </div>
              <!-- Full Name -->
              <validation-provider
                #default="validationContext"
                name="Project Name"
                required
              >
                <b-form-group label="Project Name" label-for="project-name">
                  <b-form-input
                    id="project-name"
                    v-model="productData.name"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="My Project"
                  />
  
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
  
              <b-form-group
                label="Should Capture Analytics:"
                label-for="collect_analytics"
              >
                <div style="display: flex;" class="mb-2">
                  <b-form-checkbox
                    v-model="productData.collect_analytics"
                    inline
                    id="collect_analytics"
                  >
                  </b-form-checkbox>
                </div>
              </b-form-group>
              <b-form-group label="Comment" label-for="comment">
                <b-form-textarea
                  id="textarea"
                  v-model="productData.comment"
                  placeholder="Comment"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>
              <b-form-group
                label="Chose Project Type:"
              >
                <div style="display: flex;" class="mb-2">
                  <b-form-radio-group
                    v-model="productData.project_type"
                    class="mb-1"
                    disabled-field="notEnabled"
                  >
                    <b-form-radio value="internal">Internal</b-form-radio>
                    <b-form-radio
                      value="external"
                      :disabled="!hasLicensePermission('project-type', 'external')"
                    >
                      External
                    </b-form-radio>
                    <a
                      v-if="!hasLicensePermission('project-type', 'external')"
                      href="/pricing"
                      target="_blank"
                      title="External for higher license"
                    >
                      <feather-icon
                        class="cursor-pointer"
                        icon="AlertTriangleIcon"
                        size="20"
                        style="color: #F00"
                      />
                    </a>
                  </b-form-radio-group>
                </div>
              </b-form-group>
  
              <!-- Template -->
              <p>Template name - {{template.related_project_name}}</p>
              
              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                  :disabled="submitting.status"
                >
                  Add
                  <b-spinner
                    v-if="submitting.status"
                    small
                    class="ml-1"
                    label="Small Spinner"
                  />
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="button"
                  variant="outline-secondary"
                  @click="hide"
                >
                  Cancel
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </template>
      </b-sidebar>
    </div>
  </template>
  
  <script>
  import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BFormCheckbox,
  } from "bootstrap-vue";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { ref } from "@vue/composition-api";
  import { required } from "@validations";
  import formValidation from "@core/comp-functions/forms/form-validation";
  import Ripple from "vue-ripple-directive";
  import ToastificationContent from "@core/components/toastification/ToastificationContent";
  import { BSpinner } from "bootstrap-vue";
  
  
  export default {
    components: {
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormTextarea,
      BFormInvalidFeedback,
      BButton,
      BSpinner,
      BFormCheckbox,
      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: "isAddNewUserSidebarActive",
      event: "update:is-add-new-user-sidebar-active",
    },
    props: {
      isAddNewUserSidebarActive: {
        type: Boolean,
        required: true,
      },
      template: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        required,
        isAnalytics: "",
        customizerOpenClicked: false,
        optionsProjectLicenseType: [
          { item: 1, name: "Basic" },
          { item: 2, name: "Plus" },
          { item: 3, name: "Pro" },
        ],
        submitting: {status: false},
      };
    },
    computed: {
      licensePermissions() {
        return this.$store.getters["app/LICENSE_PERMISSIONS"]
      },
      getLeftUserLicenses() {
        return this.$store.getters["Project/getLeftLicenses"]
      },
      getChosenLicenseType() {
        let licenseTypes = {1: "Basic", 2: "Plus", 3: "Pro"}
        return licenseTypes[this.productData.project_license_type]
      },
      getCountChosenLicenseLeft() {
        let licenseTypes = {1: "basic_count", 2: "plus_count", 3: "pro_count"}
        return this.getLeftUserLicenses[licenseTypes[this.productData.project_license_type]]
      },
    },
    methods: {
      onSubmit() {
        this.submitting.status = true;
  
        const req = {
          name: this.productData.name,
          comment: this.productData.comment,
          collect_analytics: this.productData.collect_analytics,
          user: this.$store.state.UserData.userData.id,
          project_type: this.productData.project_type,
          template: this.template.id,
          project_license_type: this.productData.project_license_type,
        };
  
        this.$store
          .dispatch("Project/setNewProject", req)
          .then(() => {
            this.$store.dispatch("Projects/GET_MY_PROJECTS");
  
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "You have successfully create new project",
                icon: "CoffeeIcon",
                autoHideDelay: 3000,
                variant: "success",
              },
            });
            this.$emit("refetch-data");
            this.$emit("update:is-add-new-user-sidebar-active", false);
          })
          .catch((err) => {
            console.error("error", err)
  
            this.$store.dispatch("Projects/GET_MY_PROJECTS");
  
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Something went wrong",
                icon: "AlertTriangleIcon",
                autoHideDelay: 3000,
                variant: "danger",
              },
            });
          })
          .finally(() => {
            this.submitting.status = false;
          })
      },
      hasLicensePermission(permission_type, permission_field) {
        // Look in license_permission.json to manage permission_type and permission_field
        try {
          return this.isLicenseChosen(this.licensePermissions[permission_type][permission_field].split(" "))
        } catch {
          return true
        }
      },
      callOnSubmit() {
        if (!this.productData.name) {
          this.$toast.error("Set Project name please!")
          return
        }
        if (this.getCountChosenLicenseLeft < 1) {
          this.$toast.error("No enough licenses to apply!")
          return
        }
  
        this.onSubmit()
      },
      isLicenseChosen(licenseList) {
        for (let license of licenseList) {
          if (license == this.productData.project_license_type) {
            return true
          }
        }
        return false
      },
    },
    watch: {
      isAddNewUserSidebarActive() {
        this.$store.dispatch("Project/getLeftLicenses")
      },
    },
    setup() {
      const blankproductData = {
        name: "",
        comment: "",
        collect_analytics: true,
        project_type: "internal",
        project_license_type: 1,
        template: null,
      };
  
      const productData = ref(JSON.parse(JSON.stringify(blankproductData)));
      const resetproductData = () => {
        productData.value = JSON.parse(JSON.stringify(blankproductData));
      };
  
      const { refFormObserver, getValidationState, resetForm } =
        formValidation(resetproductData);
  
      return {
        productData,
        refFormObserver,
        getValidationState,
        resetForm,
      };
    },
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  
  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  
  .template-link {
    opacity: 0.5;
  }
  
  .template-link:hover {
    opacity: 1;
  }
  
  .not-enough-license {
    color: rgb(228, 64, 64);
  }
  </style>
  